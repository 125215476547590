/** import dependencies */
import Icons from 'uikit/dist/js/uikit-icons';
import CustomIcons from './uikit-icons-assets';
import UIkit from 'uikit';

// loads the Icon plugin
window.UIkit = UIkit;
UIkit.use(Icons);
UIkit.use(CustomIcons);

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
import Router from './util/Router';
import common from './routes/common';
const routes = new Router({
  /** All pages */
  common,
});

/** Load Events */
routes.loadEvents();
